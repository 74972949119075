import React from 'react';
import './styles.css';

function DataTable({ data }) {
  if (data.length === 0) {
    return <div>Please fill the required fields</div>;
  }

  const displayData = data.length > 10 ? data.slice(-10) : data;
  const starting = `Starting Balance: $${data[0].balance.toLocaleString()}`;
  const final = `Final Balance (${data.length - 1} years): $${data[
    data.length - 1
  ].balance.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })}`;

  return (
    <div>
      <table className="datatable">
        <thead>
          <tr>
            <th>Year</th>
            <th>Balance ($)</th>
          </tr>
        </thead>
        <tbody>
          {displayData.map((item, index) => (
            <tr key={index}>
              <td>{item.year}</td>
              <td>
                {item.balance.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <p>{starting}</p>
      <p>{final}</p>
    </div>
  );
}

export default DataTable;
