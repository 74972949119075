import { Route, Routes } from 'react-router-dom';
import {
  Home,
  FNA,
  LifeExpectancy,
  Budget,
  Investment,
  Example,
} from './pages';
import PopupChat from './components/PopupChat/Popupchat';
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/budget" element={<Budget />} />
        <Route path="/fna" element={<FNA />} />
        <Route path="/investment" element={<Investment />} />
        <Route path="/life-expectancy" element={<LifeExpectancy />} />
        <Route path="/example" element={<Example />} />
      </Routes>
      <PopupChat />
    </div>
  );
}

export default App;
