import React, { useState, useEffect, useRef } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  TextField,
  OutlinedInput,
  Accordion,
  Box,
  AccordionSummary,
  AccordionDetails,
  Typography,
  InputAdornment,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  Divider,
} from '@mui/material';
import Select from '@mui/material/Select';
import BorderedSection from '../../components/BorderedSection/BorderedSection';
import { useReactToPrint } from 'react-to-print';
import { styled } from '@mui/material/styles';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

const NotPrintable = styled('div')({
  '@media print': {
    display: 'none',
  },
});

const VisiblePrintable = styled('div')({
  display: 'none', // Initially hide the element
  '@media print': {
    display: 'block', // Display the element when printing
  },
});

const FNA = () => {
  const [clientFormValues, setClientFormValues] = useState({
    fname: '',
    lname: '',
    age: 0,
    sex: '',
    smoker: '',
    meritalStatus: '',
    numberOfDependantChildren: 0,
    occupation: '',
  });
  const [assetFormValues, setAssetFormValues] = useState({
    existingLifeInsurance: 0,
    other: 0,
  });
  const [expenseFormValues, setExpenseFormValues] = useState({
    annualFamilyFinancialNeeds: 0,
    numberOfYears: 0,
    debt: 0,
    funeralExpenses: 0,
  });

  const [totalAssetsValue, setTotalAssetsValue] = useState(0);
  const [totalExpensesValue, setTotalExpensesValue] = useState(0);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'FNA',
  });
  const calculateTotalAssetsValue = () => {
    const values = Object.values(assetFormValues);

    const allNumbers = values.every((value) => !isNaN(parseFloat(value)));
    if (!allNumbers) {
      setTotalAssetsValue('Error');
      return;
    }

    const total = values.reduce((acc, value) => acc + parseFloat(value), 0);
    setTotalAssetsValue(total);
  };
  const calculateTotalExpensesValue = () => {
    const { annualFamilyFinancialNeeds, numberOfYears, debt, funeralExpenses } =
      expenseFormValues;

    const values = Object.values(expenseFormValues);

    const allNumbers = values.every((value) => !isNaN(parseFloat(value)));

    if (!allNumbers) {
      setTotalExpensesValue('Error');
      return;
    }
    if (numberOfYears == 0) {
      const total =
        parseInt(annualFamilyFinancialNeeds, 10) +
        parseInt(debt, 10) +
        parseInt(funeralExpenses, 10);

      console.log(total);
      console.log(annualFamilyFinancialNeeds);
      console.log(debt);
      console.log(funeralExpenses);
      setTotalExpensesValue(total);
    } else {
      setTotalExpensesValue(
        parseInt(annualFamilyFinancialNeeds, 10) * parseInt(numberOfYears, 10) +
          parseInt(debt, 10) +
          parseInt(funeralExpenses, 10)
      );
    }
  };

  const handleClientChange = (event) => {
    const { name, value } = event.target;
    setClientFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  const handleAssetChange = (event) => {
    const { name, value } = event.target;
    setAssetFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
    calculateTotalAssetsValue();
  };

  const handleExpenseChange = (event) => {
    const { name, value } = event.target;
    setExpenseFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
    calculateTotalExpensesValue();
  };

  useEffect(() => {
    calculateTotalAssetsValue();
    calculateTotalExpensesValue();
  }, [assetFormValues, expenseFormValues]);

  return (
    <div>
      <Box className="form">
        <h1>Financial Needs Analysis</h1>
      </Box>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        className="form"
        ref={componentRef}
        sx={{ marginTop: '10px' }}
      >
        <NotPrintable>
          <p>
            <i>
              *Please avoid using commas when inputting raw numbers, instead
              enter the raw numbers directly. For example, input "10000" instead
              of "10,000".
            </i>
          </p>
        </NotPrintable>
        <BorderedSection title="Client Information">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              flexWrap: 'wrap',
              marginTop: '10px',
            }}
          >
            <TextField
              id="client-fname"
              label="First Name"
              variant="outlined"
              color="success"
              sx={{ width: '22.5%' }}
              name="fname"
              onChange={handleClientChange}
            />
            <TextField
              id="client-lname"
              label="Last Name"
              variant="outlined"
              color="success"
              sx={{ width: '22.5%' }}
              name="lname"
              onChange={handleClientChange}
            />
            <TextField
              id="client-age"
              label="Age"
              variant="outlined"
              type="number"
              color="success"
              sx={{ width: '22.5%' }}
              name="age"
              onChange={handleClientChange}
            />
            <FormControl color="success" sx={{ width: '22.5%' }}>
              <InputLabel id="client-sex">Sex</InputLabel>
              <Select
                labelId="client-sex"
                id="clientSex"
                value={clientFormValues.sex}
                name="sex"
                label="Sex"
                onChange={handleClientChange}
              >
                <MenuItem value={'Male'}>Male</MenuItem>
                <MenuItem value={'Female'}>Female</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              flexWrap: 'wrap',
              marginTop: '20px',
            }}
          >
            <TextField
              id="client-marital-status"
              label="Marital Status"
              variant="outlined"
              color="success"
              sx={{ width: '22.5%' }}
              name="meritalStatus"
              onChange={handleClientChange}
            />
            <TextField
              id="client-occupation"
              label="Occupation"
              variant="outlined"
              color="success"
              sx={{ width: '22.5%' }}
              name="occupation"
              onChange={handleClientChange}
            />
            <TextField
              id="client-dependant-children"
              label="Number of Dependant Children"
              variant="outlined"
              type="number"
              color="success"
              sx={{ width: '22.5%' }}
              name="numberOfDependantChildren"
              onChange={handleClientChange}
            />
            <FormControl color="success" sx={{ width: '22.5%' }}>
              <InputLabel id="client-smoker">Smoker</InputLabel>
              <Select
                labelId="client-smoker"
                id="clientSmoker"
                value={clientFormValues.smoker}
                name="smoker"
                label="Smoker"
                onChange={handleClientChange}
              >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
              </Select>
            </FormControl>
          </div>
        </BorderedSection>
        <BorderedSection title="Family Financial Needs">
          <Typography sx={{ fontSize: '0.8em' }}>
            The annual amount needed to maintain your household's lifestyle,
            multiplied by the number of years that this amount would be needed,
            in order to compensate for your death; if insurance will be only
            used for debt repayment at death, input “0” for the “number of
            years”.
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '10px',
            }}
          >
            <FormControl sx={{ width: '49.5%' }} color="success" type="number">
              <InputLabel htmlFor="FNA-annualFamilyFinancialNeeds">
                Annual Amount
              </InputLabel>
              <OutlinedInput
                id="FNA-annualFamilyFinancialNeeds"
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                label="Annual Amount"
                name="annualFamilyFinancialNeeds"
                value={expenseFormValues.annualFamilyFinancialNeeds}
                onChange={handleExpenseChange}
              />
            </FormControl>
            <FormControl
              sx={{ width: '49.5%' }}
              color="success"
              required
              type="number"
            >
              <InputLabel htmlFor="FNA-number-of-years">
                Number of Years
              </InputLabel>
              <OutlinedInput
                id="FNA-number-of-years"
                endAdornment={
                  <InputAdornment position="end">Years</InputAdornment>
                }
                label="Number of Years"
                name="numberOfYears"
                value={expenseFormValues.numberOfYears}
                onChange={handleExpenseChange}
              />
            </FormControl>
          </div>
        </BorderedSection>
        <BorderedSection title="Total Debt and/or Housing Cost">
          <Typography sx={{ fontSize: '0.8em' }}>
            Credit, mortgage, rent amount x 30, etc.
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '10px',
            }}
          >
            <FormControl color="success" type="number" fullWidth>
              <InputLabel htmlFor="FNA-debt">Total Amount of Debt</InputLabel>
              <OutlinedInput
                id="FNA-debt"
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                label="Total Amount of Debt"
                name="debt"
                value={expenseFormValues.debt}
                onChange={handleExpenseChange}
              />
            </FormControl>
          </div>
        </BorderedSection>
        <BorderedSection title="Funeral Expenses">
          <Typography sx={{ fontSize: '0.8em' }}>
            Legal fees, funeral expenses, and taxes, will be anywhere from 5 -
            15k
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '10px',
            }}
          >
            <FormControl color="success" type="number" fullWidth>
              <InputLabel htmlFor="FNA-funeral-expenses">
                Total Death Expenses Coverage Needed
              </InputLabel>
              <OutlinedInput
                id="FNA-debt"
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                label="Total Death Expenses Coverage Needed"
                name="funeralExpenses"
                value={expenseFormValues.funeralExpenses}
                onChange={handleExpenseChange}
              />
            </FormControl>
          </div>
        </BorderedSection>
        <BorderedSection title="Existing Life Insurance">
          <Typography sx={{ fontSize: '0.8em' }}>
            The total amount of life insurance currently held.
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '10px',
            }}
          >
            <FormControl color="success" type="number" fullWidth>
              <InputLabel htmlFor="FNA-existing-life-insurance">
                Total Amount of Life Insurance
              </InputLabel>
              <OutlinedInput
                id="FNA-existing-life-insurance"
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                label="Total Amount of Life Insurance"
                name="existingLifeInsurance"
                value={assetFormValues.existingLifeInsurance}
                onChange={handleAssetChange}
              />
            </FormControl>
          </div>
        </BorderedSection>
        <BorderedSection title="Other Savings or Assets">
          <Typography sx={{ fontSize: '0.8em' }}>
            Assets that will be liquidated in the event of your death in
            estimated dollar value
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '10px',
            }}
          >
            <FormControl color="success" type="number" fullWidth>
              <InputLabel htmlFor="FNA-other">Total Savings</InputLabel>
              <OutlinedInput
                id="FNA-other"
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                label="Total Savings"
                name="other"
                value={assetFormValues.other}
                onChange={handleAssetChange}
              />
            </FormControl>
          </div>
        </BorderedSection>
        <BorderedSection title="Total">
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Typography sx={{ fontSize: '0.8em' }}>
              <b>Assets: </b>${totalAssetsValue.toLocaleString()}
            </Typography>
            <Typography sx={{ fontSize: '0.8em' }}>
              <b>Expenses: </b>${totalExpensesValue.toLocaleString()}
            </Typography>
            <Typography sx={{ fontSize: '0.8em' }}>
              <b>Estimated Need: </b>$
              {(totalAssetsValue - totalExpensesValue).toLocaleString()}
            </Typography>
          </div>
        </BorderedSection>
        <NotPrintable>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '90%',
              }}
            >
              {totalAssetsValue - totalExpensesValue == 0 ? (
                <h3 style={{ marginTop: '0px', color: 'red' }}>
                  <i>
                    *Based on the calculation, your assets will equally cover
                    your liabilities; there is still a need for life insurance.
                    Many individuals do not want to liquidate/sell assets in the
                    event of their death. They also consider factors such as:
                    family security, comfortability, and leaving a legacy.
                  </i>
                </h3>
              ) : (
                ''
              )}
              {totalAssetsValue - totalExpensesValue > 0 ? (
                <h3
                  style={{ marginTop: '0px', color: 'red', fontWeight: 'bold' }}
                >
                  <i>
                    `Based on the calculation, your assets outweigh your
                    financial burden at death by $
                    {(totalAssetsValue - totalExpensesValue).toLocaleString()};
                    there is no technical need for life insurance. Although many
                    individuals do not want to liquidate/sell assets in the
                    event of their death. They also consider factors such as:
                    family security, comfortability, and leaving a legacy.`
                  </i>
                </h3>
              ) : (
                ''
              )}
              {totalAssetsValue - totalExpensesValue < 0 ? (
                <h3
                  style={{ marginTop: '0px', color: 'red', fontWeight: 'bold' }}
                >
                  <i>
                    `Based on the calculation, you have a life insurance need of
                    $
                    {Math.abs(
                      totalAssetsValue - totalExpensesValue
                    ).toLocaleString()}{' '}
                    to financially protect your family at the time of your
                    death`
                  </i>
                </h3>
              ) : (
                ''
              )}
            </div>
          </div>

          <Divider variant="middle" sx={{ margin: '40px 0' }} />
        </NotPrintable>
        <VisiblePrintable>
          <div style={{ height: '100px' }}></div>
        </VisiblePrintable>
        <BorderedSection title="Advisor Recommendation & Reason">
          <TextField
            id="advisor-recommendation-reason"
            multiline
            maxRows={4}
            color="success"
            fullWidth
            placeholder="Please provide your recommendation here"
          />
        </BorderedSection>
        <BorderedSection title="Details/Signatures">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <TextField
              id="input-with-icon-textfield"
              label="Advisor"
              color="success"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ClearRoundedIcon sx={{ fontSize: '20px' }} />
                  </InputAdornment>
                ),
                sx: {
                  '& input': {
                    fontFamily: 'Cedarville Cursive, cursive',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  },
                },
              }}
              variant="standard"
            />
            <TextField
              id="input-with-icon-textfield"
              label="Client"
              color="success"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ClearRoundedIcon sx={{ fontSize: '20px' }} />
                  </InputAdornment>
                ),
                sx: {
                  '& input': {
                    fontFamily: 'Cedarville Cursive, cursive',
                    fontWeight: 'bold',
                    textAlign: 'center',
                  },
                },
              }}
              value={clientFormValues.fname + ' ' + clientFormValues.lname}
              variant="standard"
            />
            <Typography sx={{ fontSize: '0.8em' }}>
              <b>Date: </b>
              {new Date().toLocaleDateString()}
            </Typography>
          </div>
        </BorderedSection>

        <VisiblePrintable>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '90%',
              }}
            >
              {totalAssetsValue - totalExpensesValue == 0 ? (
                <h3 style={{ marginTop: '0px', color: 'red' }}>
                  <i>
                    *Based on the calculation, your assets will equally cover
                    your liabilities; there is still a need for life insurance.
                    Many individuals do not want to liquidate/sell assets in the
                    event of their death. They also consider factors such as:
                    family security, comfortability, and leaving a legacy.
                  </i>
                </h3>
              ) : (
                ''
              )}
              {totalAssetsValue - totalExpensesValue > 0 ? (
                <h3
                  style={{ marginTop: '0px', color: 'red', fontWeight: 'bold' }}
                >
                  <i>
                    `Based on the calculation, your assets outweigh your
                    financial burden at death by $
                    {(totalAssetsValue - totalExpensesValue).toLocaleString()};
                    there is no technical need for life insurance. Although many
                    individuals do not want to liquidate/sell assets in the
                    event of their death. They also consider factors such as:
                    family security, comfortability, and leaving a legacy.`
                  </i>
                </h3>
              ) : (
                ''
              )}
              {totalAssetsValue - totalExpensesValue < 0 ? (
                <h3
                  style={{ marginTop: '0px', color: 'red', fontWeight: 'bold' }}
                >
                  <i>
                    `Based on the calculation, you have a life insurance need of
                    $
                    {Math.abs(
                      totalAssetsValue - totalExpensesValue
                    ).toLocaleString()}{' '}
                    to financially protect your family at the time of your
                    death`
                  </i>
                </h3>
              ) : (
                ''
              )}
            </div>
          </div>
        </VisiblePrintable>
      </Box>

      <Box className="form">
        <Button variant="contained" onClick={handlePrint}>
          Download
        </Button>
      </Box>
      <Box className="form">
        <div>
          <h1>FAQ</h1>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="FNA-FAQ1-content"
              id="FNA-FAQ1-header"
            >
              <Typography sx={{ fontWeight: 'bold' }}>
                What is a financial needs analysis calculator?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                A financial needs analysis calculator is a tool used to assess
                your financial situation by comparing your assets (e.g.,
                savings, investments, property) with your expenses. It helps you
                determine whether you have enough resources to cover your
                financial needs or if there is a shortfall.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="FNA-FAQ2-content"
              id="FNA-FAQ2-header"
            >
              <Typography sx={{ fontWeight: 'bold' }}>
                What does a positive result and a negative result indicate?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                A positive result indicates that your assets exceed your
                expenses, suggesting a surplus and a potentially good financial
                position. On the other hand, a negative result means that your
                expenses exceed your assets, indicating a financial shortfall
                that may require budget review or exploring ways to increase
                income or reduce expenses.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="FNA-FAQ3-content"
              id="FNA-FAQ3-header"
            >
              <Typography sx={{ fontWeight: 'bold' }}>
                Is the financial needs analysis calculator 100% accurate?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                The calculator provides a basic assessment based on the formula
                assets - expenses. However, it's important to note that it
                doesn't take into account all financial factors, such as
                investment returns or unexpected expenses. It should be used as
                a starting point for financial analysis rather than a definitive
                measure.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
        <div>
          <h1>Glossary / Criteria</h1>
          <p>
            <b>Other Savings or Assets:</b> Cash, savings, current emergency
            funds, liquid investments (TFSA), etc.
          </p>

          <p>
            <b>Current Coverage:</b> Value of any other coverage you may have
            (work benefits, etc)
          </p>
          <p>
            <b>Income Replacement:</b> 5x annual income
          </p>
          <p>
            <b>Budget/Rent:</b> Remaining mortgage, or monthly rent x 30months
          </p>
          <p>
            <b>Outstanding Debt:</b> Loans, credit cards, personal, etc
          </p>
          <p>
            <b>Funeral Costs:</b> $10-15k
          </p>
          <p>
            <b>Cash Needs:</b> Amount of $$$ needed in the event a spouse has
            passed (either for comfort or basic necessities for x amount of
            time)
          </p>
        </div>
      </Box>
    </div>
  );
};

/*
<div style={{ padding: '2em' }}>
      <BorderedSection title="Title only">
        <div>a first child with quite a long text</div>
        <div>a second child</div>
      </BorderedSection>
    </div>
    */

export default FNA;
