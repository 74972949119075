import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DataTable from '../../components/DataTable/DataTable';

import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  Label,
} from 'recharts';

const Investment = () => {
  let A;
  let [data, setData] = useState([]);
  /* 
  const data = [
    { year: 20, balance: 10 },
    { year: 25, balance: 15 },
    { year: 30, balance: 12 },
    { year: 35, balance: 18 },
    { year: 40, balance: 20 },
    { year: 45, balance: 16 },
    { year: 50, balance: 22 },
    { year: 55, balance: 25 },
    { year: 60, balance: 30 },
    // Add more data points as needed
  ];
  */

  const [formValues, setFormValues] = useState({
    startingBalance: '',
    contributionAmount: '',
    growthTime: '',
    annualInterestRate: '',
    contributionFrequency: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  // Future Value of an Annuity Formula
  const calculateFutureValueOfAnnuity = (P, r, n, t, C) => {
    const A =
      P * (1 + r / n) ** (n * t) + C * (((1 + r / n) ** (n * t) - 1) / (r / n));
    return A;
  };

  const calculation = () => {
    if (formValues.contributionFrequency === 'monthly') {
      const n = 12; // Monthly contributions
      let calculatedData = [];
      for (let years = 0; years <= formValues.growthTime; years++) {
        A = calculateFutureValueOfAnnuity(
          formValues.startingBalance,
          formValues.annualInterestRate / 100,
          n,
          years,
          formValues.contributionAmount
        );
        calculatedData.push({ year: years, balance: A });
      }
      setData(calculatedData);
    } else if (formValues.contributionFrequency === 'annually') {
      const n = 1; // Annual contributions
      let calculatedData = [];
      for (let years = 0; years <= formValues.growthTime; years++) {
        A = calculateFutureValueOfAnnuity(
          formValues.startingBalance,
          formValues.annualInterestRate / 100,
          n,
          years,
          formValues.contributionAmount
        );
        calculatedData.push({ year: years, balance: A });
      }
      setData(calculatedData);
    }
  };

  useEffect(() => {
    calculation();
    //console.log(formValues);
    console.log(data);
  }, [formValues]);

  return (
    <Box component="form" noValidate autoComplete="off" className="form">
      <h1>Investment Calculator</h1>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '10px',
        }}
      >
        <FormControl
          sx={{ width: '49.5%' }}
          color="success"
          required
          type="number"
        >
          <InputLabel htmlFor="Investments-starting-balance">
            Starting Balance
          </InputLabel>
          <OutlinedInput
            id="Investments-starting-balance"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            label="Starting Balance"
            name="startingBalance"
            value={formValues.startingBalance}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl
          sx={{ width: '49.5%' }}
          color="success"
          required
          type="number"
        >
          <InputLabel htmlFor="Investments-contribution-amount">
            Contribution Amount
          </InputLabel>
          <OutlinedInput
            id="Investments-contribution-amount"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            label="Contribution Amount"
            name="contributionAmount"
            value={formValues.contributionAmount}
            onChange={handleChange}
          />
        </FormControl>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '10px',
        }}
      >
        <FormControl
          sx={{ width: '49.5%' }}
          color="success"
          required
          type="number"
        >
          <InputLabel htmlFor="Investments-growth-time">Growth Time</InputLabel>
          <OutlinedInput
            id="Investments-growth-time"
            endAdornment={<InputAdornment position="end">Years</InputAdornment>}
            label="Growth Time"
            name="growthTime"
            value={formValues.growthTime}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl
          sx={{ width: '49.5%' }}
          color="success"
          required
          type="number"
        >
          <InputLabel htmlFor="Investment-annual-interest-rate">
            Annual Interest Rate
          </InputLabel>
          <OutlinedInput
            id="Investment-annual-interest-rate"
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
            label="Annual Interest Rate"
            name="annualInterestRate"
            value={formValues.annualInterestRate}
            onChange={handleChange}
          />
        </FormControl>
      </div>
      <div>
        <FormControl fullWidth required color="success">
          <InputLabel id="contributionFrequency-label">
            Contribution Frequency
          </InputLabel>
          <Select
            labelId="contributionFrequency-label"
            id="contributionFrequency"
            value={formValues.contributionFrequency}
            name="contributionFrequency"
            label="Contribution Frequency"
            onChange={handleChange}
          >
            <MenuItem value={'monthly'}>Monthly</MenuItem>
            <MenuItem value={'annually'}>Annually</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div style={styles.container}>
        <ResponsiveContainer style={{ padding: '100px', margin: '100px' }}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year">
              <Label
                value="Year"
                position="insideBottom"
                style={{ textAnchor: 'middle', fontWeight: 'bold' }}
                offset={-5}
              />
            </XAxis>
            <YAxis
              dataKey="balance"
              width={90}
              tickFormatter={(value) => value.toLocaleString()}
            >
              <Label
                value="Balance ($)"
                position="left"
                angle={-90}
                style={{ textAnchor: 'middle', fontWeight: 'bold' }}
                offset={0}
              />
            </YAxis>
            <Tooltip
              formatter={(value) =>
                value.toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })
              }
            />
            <Line type="monotone" dataKey="balance" stroke="#8884d8" />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div>
        <h1>Data Table</h1>
        <DataTable data={data} />
      </div>

      <div>
        <h1>FAQ</h1>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="Investments-FAQ1-content"
            id="Investments-FAQ1-header"
          >
            <Typography sx={{ fontWeight: 'bold' }}>
              What is compound interest?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Compound interest is the concept of earning interest not only on
              the initial principal amount but also on the accumulated interest
              from previous periods. It allows your investment to grow
              exponentially over time.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="Investments-FAQ2-content"
            id="Investments-FAQ2-header"
          >
            <Typography sx={{ fontWeight: 'bold' }}>
              How does compound interest differ from simple interest?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Compound interest differs from simple interest in that it takes
              into account the accumulated interest from previous periods. With
              compound interest, your investment grows at an increasing rate,
              while simple interest only applies to the initial principal
              amount.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="Investments-FAQ3-content"
            id="Investments-FAQ3-header"
          >
            <Typography sx={{ fontWeight: 'bold' }}>
              How can I calculate compound interest using a compound interest
              calculator?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              To calculate compound interest using a compound interest
              calculator, you typically need to input the starting balance or
              principal amount, the annual interest rate, the compounding
              frequency (e.g., monthly, quarterly), and the time period. The
              calculator will then provide you with the future value of your
              investment, including the compounded interest earned over the
              given duration.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
      <div>
        <h1>Glossary</h1>
        <p>
          <b>Starting Balance:</b> The initial amount of money you have invested
          or the principal amount.
        </p>
        <p>
          <b>Contribution Amount:</b> The additional amount of money you
          regularly add to your investment at specific intervals, such as
          monthly or annually.
        </p>
        <p>
          <b>Growth Time:</b> The duration for which your investment will be
          growing, typically measured in years.
        </p>
        <p>
          <b>Annual Interest Rate:</b> The rate at which your investment grows
          each year, expressed as a percentage or a decimal. It represents the
          interest earned on the investment.
        </p>
        <p>
          <b>Contribution Frequency:</b> The frequency at which you make
          contributions to your investment. It refers to how often you add the
          contribution amount to your investment, such as monthly, quarterly, or
          annually.
        </p>
      </div>
    </Box>
  );
};

const styles = {
  container: {
    width: '90%',
    height: '300px',
    margin: '0 auto',
  },
};

export default Investment;
