import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    margin: '10px',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    width: '75%',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '8px',
  },
  message: {
    fontSize: '1rem',
    color: 'rgba(0, 0, 0, 0.6)',
  },
};

const Post = (props) => {
  return (
    <div style={styles.container}>
      <Card raised elevation={6} style={styles.card}>
        <CardContent>
          <Link
            component="span"
            name="test"
            to={props.link}
            style={styles.link}
          >
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              style={styles.title}
            >
              {props.title}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              style={styles.message}
            >
              {props.message.split(' ').splice(0, 20).join(' ')}...
            </Typography>
          </Link>
        </CardContent>
      </Card>
    </div>
  );
};

export default Post;
/*
import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const Post = (props) => {
  return (
    <Card raised elevation={6} style={{ margin: '10px' }}>
      <CardContent>
        <Link component="span" name="test" to={props.link}>
          <Typography gutterBottom variant="h5" component="h2">
            {props.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {props.message.split(' ').splice(0, 20).join(' ')}...
          </Typography>
        </Link>
      </CardContent>
    </Card>
  );
};

export default Post;
*/
