import React, { useState } from 'react';
import './Popupchat.css';
import ChatBox from '../ChatBox/ChatBox';
import Mascot from '../../assets/clftech.png';

export const PopupChat = () => {
  let hide = {
    display: 'none',
  };
  let show = {
    display: 'block',
  };

  const [chatopen, setChatopen] = useState(false);
  const toggle = (e) => {
    setChatopen(!chatopen);
  };

  return (
    <div id="chatCon">
      <div className="chat-box" style={chatopen ? show : hide}>
        <div className="header">CL Chat Bot</div>
        <ChatBox />
      </div>
      <div>
        <div className="pop">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center', // Center vertically
              width: 'fit-content', // Set the width to fit its content
            }}
          >
            <p className="pre-chat-text" style={chatopen ? hide : show}>
              Click Me To Chat
            </p>
            <p>
              <img onClick={toggle} src={Mascot} alt="" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
//src="https://p7.hiclipart.com/preview/151/758/442/iphone-imessage-messages-logo-computer-icons-message.jpg"

export default PopupChat;
