import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from 'recharts';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const Budget = () => {
  const [data, setData] = useState([]);
  const [formValues, setFormValues] = useState({
    income: 0,
    necessities: 0,
    wants: 0,
    savingsAndDebt: 0,
    rule: '',
  });

  const [customFormValues, setCustomFormValues] = useState({
    necessitiesPercentage: '',
    wantsPercentage: '',
    savingsAndDebtPercentage: '',
  });

  const useRule = (event) => {
    const { name, value } = event.target;
    if (value === '50/30/20') {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        rule: '50/30/20',
        necessities: (prevFormValues.income * 0.5).toFixed(2),
        wants: (prevFormValues.income * 0.3).toFixed(2),
        savingsAndDebt: (prevFormValues.income * 0.2).toFixed(2),
      }));
    } else if (value === '70/10/20') {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        rule: '70/10/20',
        necessities: (prevFormValues.income * 0.7).toFixed(2),
        wants: (prevFormValues.income * 0.1).toFixed(2),
        savingsAndDebt: (prevFormValues.income * 0.2).toFixed(2),
      }));
    } else if (value === 'custom') {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        rule: 'custom',
        necessities:
          prevFormValues.income *
          (customFormValues.necessitiesPercentage / 100),
        wants: prevFormValues.income * (customFormValues.wantsPercentage / 100),
        savingsAndDebt:
          prevFormValues.income *
          (customFormValues.savingsAndDebtPercentage / 100),
      }));
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  const handleCustomChange = (event) => {
    const { name, value } = event.target;
    setCustomFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      rule: 'custom',
      necessities:
        prevFormValues.income * (customFormValues.necessitiesPercentage / 100),
      wants: prevFormValues.income * (customFormValues.wantsPercentage / 100),
      savingsAndDebt:
        prevFormValues.income *
        (customFormValues.savingsAndDebtPercentage / 100),
    }));
  };

  const updateData = () => {
    const updatedData = [
      { name: 'Necessities', value: parseFloat(formValues.necessities) },
      { name: 'Wants', value: parseFloat(formValues.wants) },
      {
        name: 'Savings and Debt',
        value: parseFloat(formValues.savingsAndDebt),
      },
    ];

    setData(updatedData);
  };

  useEffect(() => {
    if (formValues.income !== 0) {
      if (formValues.rule === '50/30/20') {
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          rule: '50/30/20',
          necessities: (prevFormValues.income * 0.5).toFixed(2),
          wants: (prevFormValues.income * 0.3).toFixed(2),
          savingsAndDebt: (prevFormValues.income * 0.2).toFixed(2),
        }));
      } else if (formValues.rule === '70/10/20') {
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          rule: '70/10/20',
          necessities: (prevFormValues.income * 0.7).toFixed(2),
          wants: (prevFormValues.income * 0.1).toFixed(2),
          savingsAndDebt: (prevFormValues.income * 0.2).toFixed(2),
        }));
      } else if (formValues.rule === 'custom') {
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          rule: 'custom',
          necessities:
            prevFormValues.income *
            (customFormValues.necessitiesPercentage / 100),
          wants:
            prevFormValues.income * (customFormValues.wantsPercentage / 100),
          savingsAndDebt:
            prevFormValues.income *
            (customFormValues.savingsAndDebtPercentage / 100),
        }));
      }
    }
  }, [formValues.income, customFormValues]);

  useEffect(() => {
    updateData();
  }, [formValues, customFormValues]);

  return (
    <Box component="form" noValidate autoComplete="off" className="form">
      <h1>Budget Calculator</h1>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '10px',
        }}
      >
        <FormControl
          sx={{ width: '49.5%' }}
          color="success"
          required
          type="number"
        >
          <InputLabel htmlFor="Monthly-income">Monthly Income</InputLabel>
          <OutlinedInput
            id="Monthly-income"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            label="Monthly Income"
            name="income"
            value={formValues.income}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl required color="success" sx={{ width: '49.5%' }}>
          <InputLabel id="budgetRule-label">Budget Rule</InputLabel>
          <Select
            labelId="budgetRule-label"
            id="budgetRule"
            value={formValues.rule}
            name="rule"
            label="Budget Rule"
            onChange={useRule}
          >
            <MenuItem value={'50/30/20'}>50/30/20</MenuItem>
            <MenuItem value={'70/10/20'}>70/10/20</MenuItem>
            <MenuItem value={'custom'}>Custom</MenuItem>
          </Select>
        </FormControl>
      </div>
      {formValues.rule === 'custom' && (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '10px',
            }}
          >
            <FormControl sx={{ width: '33%' }} color="success" type="number">
              <InputLabel htmlFor="custom-necessities-percentage">
                Necessities
              </InputLabel>
              <OutlinedInput
                id="Custom-necessities-percentage"
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                label="Necessities"
                name="necessitiesPercentage"
                value={customFormValues.necessitiesPercentage}
                onChange={handleCustomChange}
              />
            </FormControl>
            <FormControl sx={{ width: '33%' }} color="success" type="number">
              <InputLabel htmlFor="custom-wants-percentage">Wants</InputLabel>
              <OutlinedInput
                id="Custom-wants-percentage"
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                label="Wants"
                name="wantsPercentage"
                value={customFormValues.wantsPercentage}
                onChange={handleCustomChange}
              />
            </FormControl>
            <FormControl sx={{ width: '33%' }} color="success" type="number">
              <InputLabel htmlFor="custom-savings-and-debt-percentage">
                Savings and Debt
              </InputLabel>
              <OutlinedInput
                id="Custom-savings-and-debt-percentage"
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                label="Savings And Debt"
                name="savingsAndDebtPercentage"
                value={customFormValues.savingsAndDebtPercentage}
                onChange={handleCustomChange}
              />
            </FormControl>
          </div>
          <p>
            *Please ensure that the percentages add up to 100% to maintain a
            balanced budget.
          </p>
        </div>
      )}

      <h3>Essential Expenses: ${formValues.necessities}</h3>
      <h3>Wants: ${formValues.wants}</h3>
      <h3>Savings / Debt Repayment: ${formValues.savingsAndDebt}</h3>

      {formValues.necessities &&
      formValues.wants &&
      formValues.savingsAndDebt ? (
        <div style={styles.container}>
          <ResponsiveContainer>
            <PieChart width={400} height={300}>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
              >
                {data.map((entry, index) => (
                  <Cell key={index} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Legend verticalAlign="bottom" height={36} />
            </PieChart>
          </ResponsiveContainer>
        </div>
      ) : (
        ''
      )}
      <div>
        <h1>FAQ</h1>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="budget-FAQ1-content"
            id="budget-FAQ1-header"
          >
            <Typography sx={{ fontWeight: 'bold' }}>
              What is the 50/30/20 rule?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              The 50/30/20 budget rule suggests dividing your take-home pay into
              three categories: 50% for essential expenses, such as rent or
              mortgage payments, utilities, and groceries; 30% for discretionary
              spending, like dining out, entertainment, and shopping; and
              finally, 20% for savings and debt repayment.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="budget-FAQ2-content"
            id="budget-FAQ2-header"
          >
            <Typography sx={{ fontWeight: 'bold' }}>
              What is the 70/20/10 rule?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              The rule states that around 70% of your take-home pay should go
              towards your essential expenses, 20% should go to wants, and 10%
              should go to savings. For the average person, this is the
              budgeting method that feels most natural.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="budget-FAQ3-content"
            id="budget-FAQ3-header"
          >
            <Typography sx={{ fontWeight: 'bold' }}>
              How can a budget calculator help me manage my finances
              effectively?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              A budget calculator is a powerful tool that can significantly
              assist you in managing your finances. You can make informed
              decisions about your spending, prioritize your expenses, and
              allocate funds wisely, ultimately leading to better financial
              stability and the ability to save for future needs.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="budget-FAQ4-content"
            id="budget-FAQ4-header"
          >
            <Typography sx={{ fontWeight: 'bold' }}>
              Why is budgeting important for financial stability?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Budgeting is crucial for achieving and maintaining financial
              stability. It allows individuals and businesses to have a clear
              understanding of their income and expenses, enabling them to make
              informed decisions about how to allocate their resources. By
              creating a budget, one can prioritize financial goals, track
              spending habits, and identify areas where expenses can be reduced
              or optimized. Budgeting helps establish a framework for saving,
              reducing debt, and preparing for unexpected expenses, thus
              fostering financial security and reducing the risk of financial
              hardships.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </Box>
  );
};

const styles = {
  container: {
    width: '100%',
    height: '300px',
    margin: '0 auto',
  },
  '@media (max-width: 576px)': {
    container: {
      width: '100%',
    },
  },
};

export default Budget;
