import React from 'react';
import Post from '../components/PostCard/Post';
import Logo from '../assets/logo512.png';

const Home = () => {
  return (
    <div style={{ marginTop: '10px' }}>
      <div className="form" style={{ marginBottom: '0px' }}>
        <div style={{ display: 'flex', marginBottom: '15px' }}>
          <img
            src={Logo}
            alt="Image"
            style={{ width: '100px', height: 'auto' }}
          />
        </div>
      </div>

      <Post
        link="/budget"
        title="Budget Calculator"
        message="Use this budget calculator to determine how much you should be: saving, investing, and spending"
      />
      <Post
        link="/fna"
        title="FNA Calculator"
        message="Use this financial needs analysis to determine how much life insurance you need for your dependents to be protected at the event of your death"
      />
      <Post
        link="/investment"
        title="Investment Calculator"
        message="Use this investment calculator to see how compound interest can grow your money over time"
      />
      <Post
        link="/life-expectancy"
        title="Life Expectancy Calculator"
        message="Use this life expectancy calculator to determine how much time you have left (according to statistics)"
      />
      {/*<Post link="/example" title="FNA V2" message="FNA V2 Example" /> */}
    </div>
  );
};

export default Home;
