import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import {
  Box,
  MenuItem,
  Select,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  TextField,
} from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

const LifeExpectancy = () => {
  const [formValues, setFormValues] = useState({
    age: '',
    gender: '',
    heightFT: '',
    heightIN: '',
    weight: '',
    bmi: '',
    activity: '',
    health: '',
    smoking: '',
    alcohol: '',
    diet: '',
    stress: '',
    lifeExpectancy: '',
  });

  const calculateLifeExpectancy = () => {
    // Apply weights to options
    const genderPoints = {
      Female: 11.5,
      Male: 9.5,
      Other: 9.5,
    };

    const smokingPoints = {
      'Non-smoker': 18,
      '1-3 cigarettes': 9.5,
      '4-7 cigarettes': 3.5,
      '8+ cigarettes': 2,
    };

    const alcoholPoints = {
      'Non-drinker': 20,
      'Light drinker': 14, //1.2/day
      'Moderate drinker': 9, //2.2
      'Heavy drinker': 0, //3.5
    };

    const activityPoints = {
      None: 0,
      '1-2 times weekly': 4,
      '3-5 times weekly': 9,
      '6-7 times weekly': 14,
    };

    const dietPoints = {
      Unhealthy: 3,
      Balanced: 11,
      'Mostly healthy': 13.5,
      Healthy: 16.5,
    };

    const healthPoints = {
      zero: 7,
      one: 5,
      two: 3,
      three: 0,
    };
    /*
 What is your BMI? Options: BMI is less than 18.5, it falls within the underweight range, BMI is 18.5 to <25, it falls within the healthy weight range,  BMI is 25.0 to <30, it falls within the overweight range, BMI is 30.0 or higher, it falls within the obesity range.
*/
    const bmiPoints = {
      Underweight: 10.5,
      Healthy: 14.5,
      Overweight: 8,
      Obesity: 4,
    };

    const stressPoints = {
      Low: 16.5,
      Moderate: 10.5,
      High: 6.5,
    };

    const calculateBMI = (heightFT, heightIN, weightIBS) => {
      let height = heightFT * 12 + heightIN;
      let weight = weightIBS / 2.205;
      let bmi = (weight / height ** 2) * 703;
      let bmiResult = '';

      if (bmi < 18.5) {
        bmiResult = 'Underweight';
      } else if (bmi >= 18.5 && bmi < 25) {
        bmiResult = 'Healthy';
      } else if (bmi >= 25 && bmi < 30) {
        bmiResult = 'Overweight';
      } else {
        bmiResult = 'Obesity';
      }

      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        ['bmi']: bmiResult,
      }));

      return bmiResult;
    };

    // Calculate life expectancy

    let result = Math.max(
      Math.min(
        genderPoints[formValues.gender] +
          smokingPoints[formValues.smoking] +
          alcoholPoints[formValues.alcohol] +
          activityPoints[formValues.activity] +
          dietPoints[formValues.diet] +
          bmiPoints[
            calculateBMI(
              formValues.heightFT,
              formValues.heightIN,
              formValues.weight
            )
          ] +
          healthPoints[formValues.health] +
          stressPoints[formValues.stress],
        122
      ),
      formValues.age
    );

    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      lifeExpectancy: result,
    }));

    console.log(formValues);
    console.log(formValues.lifeExpectancy);
    console.log(result);
    console.log(
      genderPoints[formValues.gender] + smokingPoints[formValues.smoking]
    );
    console.log(
      Math.min(
        genderPoints[formValues.gender] +
          smokingPoints[formValues.smoking] +
          alcoholPoints[formValues.alcohol] +
          activityPoints[formValues.activity] +
          dietPoints[formValues.diet] +
          bmiPoints[
            calculateBMI(
              formValues.heightFT,
              formValues.heightIN,
              formValues.weight
            )
          ] +
          healthPoints[formValues.health] +
          stressPoints[formValues.stress],
        122
      )
    );
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    calculateLifeExpectancy();
  };

  return (
    <div>
      <Box component="form" noValidate autoComplete="off" className="form">
        <h1>Life Expectancy Calculator</h1>
        <h2>Personal</h2>
        <FormControl sx={{ width: '49.5%' }} required>
          <FormLabel id="gender-label">Gender</FormLabel>
          <RadioGroup
            row
            aria-labelledby="gender-label"
            name="gender"
            onClick={handleChange}
          >
            <FormControlLabel value="Male" control={<Radio />} label="Male" />
            <FormControlLabel
              value="Female"
              control={<Radio />}
              label="Female"
            />
            <FormControlLabel
              value="Other"
              control={<Radio />}
              label="Gender Identity Not Listed"
            />
          </RadioGroup>
        </FormControl>
        <FormControl
          sx={{ width: '49.5%', marginLeft: '14px' }}
          color="success"
          required
          type="number"
        >
          <InputLabel htmlFor="Age">Age</InputLabel>
          <OutlinedInput
            id="Age"
            endAdornment={<InputAdornment position="end">Years</InputAdornment>}
            label="Age"
            name="age"
            value={formValues.age}
            onChange={handleChange}
          />
        </FormControl>
        <h2>Physical</h2>
        <div style={{ display: 'flex' }}>
          <FormControl sx={{ width: '49.5%' }}>
            <div style={{ display: 'flex' }}>
              <p style={{ fontSize: '1rem', fontWeight: 'bold' }}>Height:</p>
              <TextField
                name="heightFT"
                color="success"
                sx={{ width: '24.5%', marginRight: '10px', marginLeft: '10px' }}
                required
                id="height-feet"
                label="Feet"
                variant="filled"
                value={formValues.heightFT}
                onChange={handleChange}
              />
              <TextField
                name="heightIN"
                color="success"
                sx={{ width: '24.5%' }}
                required
                id="height-inches"
                label="Inches"
                variant="filled"
                value={formValues.heightIN}
                onChange={handleChange}
              />
            </div>
          </FormControl>

          <FormControl
            sx={{ width: '49.5%', marginLeft: '14px' }}
            color="success"
            required
            type="number"
          >
            <InputLabel htmlFor="Weight">Weight</InputLabel>
            <OutlinedInput
              id="Weight"
              endAdornment={<InputAdornment position="end">Ibs</InputAdornment>}
              label="Weight"
              name="weight"
              value={formValues.weight}
              onChange={handleChange}
            />
          </FormControl>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '10px',
          }}
        >
          <FormControl sx={{ width: '49.5%' }} color="success" required>
            <InputLabel id="activity">Activity</InputLabel>
            <Select
              labelId="activity-label"
              id="activity"
              value={formValues.activity}
              label="Activity"
              name="activity"
              onChange={handleChange}
            >
              <MenuItem value={'None'}>None</MenuItem>
              <MenuItem value={'1-2 times weekly'}>
                Exercise 1-2 times weekly
              </MenuItem>
              <MenuItem value={'3-5 times weekly'}>
                Exercise 3-5 times weekly
              </MenuItem>
              <MenuItem value={'6-7 times weekly'}>
                Exercise 6-7 times weekly
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ width: '49.5%' }} color="success" required>
            <InputLabel id="Health">Health</InputLabel>
            <Select
              labelId="health-label"
              id="health"
              value={formValues.health}
              label="Health"
              onChange={handleChange}
              name="health"
            >
              <MenuItem value={'zero'}>
                I have no pre-existing medical conditions.
              </MenuItem>
              <MenuItem value={'one'}>
                I have one pre-existing medical condition.
              </MenuItem>
              <MenuItem value={'two'}>
                I have two pre-existing medical conditions.
              </MenuItem>
              <MenuItem value={'three'}>
                I have three or more pre-existing medical conditions.
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <h2>Other</h2>
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '10px',
            }}
          >
            <FormControl sx={{ width: '49.5%' }} color="success" required>
              <InputLabel id="smoking-label">Smoking</InputLabel>
              <Select
                labelId="smoking-label"
                id="smoking"
                value={formValues.smoking}
                label="Smoking"
                onChange={handleChange}
                name="smoking"
              >
                <MenuItem value={'Non-smoker'}>Non-smoker</MenuItem>
                <MenuItem value={'1-3 cigarettes'}>
                  1-3 cigarettes weekly
                </MenuItem>
                <MenuItem value={'4-7 cigarettes'}>
                  4-7 cigarettes weekly
                </MenuItem>
                <MenuItem value={'8+ cigarettes'}>
                  8+ cigarettes weekly
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ width: '49.5%' }} color="success" required>
              <InputLabel id="alcohol-label">Alcohol</InputLabel>
              <Select
                labelId="alcohol"
                id="alcohol"
                value={formValues.alcohol}
                label="Alcohol"
                onChange={handleChange}
                name="alcohol"
              >
                <MenuItem value={'Non-drinker'}>I don't drink</MenuItem>
                <MenuItem value={'Light drinker'}>
                  Light drinker (1-3/week)
                </MenuItem>
                <MenuItem value={'Moderate drinker'}>
                  Moderate drinker (5/week)
                </MenuItem>
                <MenuItem value={'Heavy drinker'}>
                  Heavy drinker (5+/week)
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <FormControl sx={{ width: '49.5%' }} color="success" required>
              <InputLabel id="diet-label">Diet</InputLabel>
              <Select
                labelId="diet-label"
                id="diet"
                value={formValues.diet}
                label="Diet"
                onChange={handleChange}
                name="diet"
              >
                <MenuItem value={'Unhealthy'}>Unhealthy</MenuItem>
                <MenuItem value={'Balanced'}>Balanced</MenuItem>
                <MenuItem value={'Mostly healthy'}>Mostly Healthy</MenuItem>
                <MenuItem value={'Healthy'}>Healthy</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ width: '49.5%' }} color="success" required>
              <InputLabel id="stress-label">Stress</InputLabel>
              <Select
                labelId="stress-label"
                id="stress"
                value={formValues.stress}
                label="Stress"
                onChange={handleChange}
                name="stress"
              >
                <MenuItem value={'Low'}>Low</MenuItem>
                <MenuItem value={'Moderate'}>Moderate</MenuItem>
                <MenuItem value={'High'}>High</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <Button variant="contained" type="submit" onClick={handleSubmit}>
          Submit
        </Button>
        {formValues.lifeExpectancy !== '' && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '0px',
            }}
          >
            <p style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
              Life Expectancy: {formValues.lifeExpectancy}
            </p>
            <p style={{ fontStyle: 'italic' }}>
              Disclaimer: Please note that the life expectancy calculator
              provided is a tool intended for informational purposes only. It is
              essential to remember that numerous factors contribute to life
              expectancy, and this calculator may not capture all of them
              accurately. Variables such as genetics, socio-economic factors,
              environmental conditions, lifestyle choices, and individual health
              conditions play significant roles in determining life expectancy.
              Therefore, while the calculator can provide a general estimate, it
              is crucial to consult with healthcare professionals or experts for
              a comprehensive evaluation of your specific situation.
            </p>
          </div>
        )}
      </Box>
    </div>
  );
};

export default LifeExpectancy;

/*
Example:
Height: 5 feet 8 inches
Weight: 160 pounds

Step 1: Convert height to inches.
5 feet = 5 * 12 = 60 inches
Total height = 60 inches + 8 inches = 68 inches

Step 2: Convert weight to kilograms.
160 pounds / 2.205 = 72.57 kilograms (rounded to two decimal places)

Step 3: Calculate BMI.
BMI = (72.57 kg / (68 inches)^2) x 703
BMI = (72.57 / 4624) x 703
BMI = 0.0157 x 703
BMI ≈ 24.94

Step 4: Interpret the result.
In this example, the calculated BMI is approximately 24.94. According to the standard BMI ranges, a BMI between 18.5 and 24.9 is considered within the "normal weight" category. Therefore, based on the BMI calculation, the individual in this example would be classified as having a normal weight.

Remember that BMI is a general indicator and does not account for individual variations such as muscle mass or body composition. It's always advisable to consult with a healthcare professional for a comprehensive assessment of your health and body composition.
*/
