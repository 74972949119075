const Example = () => {
  /*
<div style={{ padding: '2em' }}>
      <BorderedSection title="Title only">
        <div>a first child with quite a long text</div>
        <div>a second child</div>
      </BorderedSection>
    </div>
    */
};
export default Example;
